body {
  -webkit-font-smoothing: antialiased;
  color: #fff;
  background-color: #000;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: calc(10px + .27vw);
}

.container {
  background-color: #eaf5ff;
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.comments {
  background-color: #fff;
  border-radius: 30px;
  margin: 5vh 3vw;
}

.v {
  padding: 2vh 3vw;
}

.v .vwrap {
  border: none !important;
}

.bg {
  background-color: #48b;
  background-image: linear-gradient(#fff 50%, #fff0 0), radial-gradient(circle closest-side, #fff 53%, #fff0 0), radial-gradient(circle closest-side, #fff 50%, #fff0 0);
  background-position: 0 0, 0 0, 55px 0;
  background-repeat: repeat-x;
  background-size: 110px 130px;
  background-attachment: scroll, scroll, scroll;
  background-origin: padding-box, padding-box, padding-box;
  background-clip: border-box, border-box, border-box;
  width: 100%;
  height: 100%;
  position: absolute;
}

.content {
  padding: 10vh 10vw 3vh;
  position: absolute;
}

h1 {
  margin-bottom: 0;
  font-size: 4.5em;
  font-weight: 500;
}

p {
  max-width: 45em;
  margin-top: .6em;
  margin-bottom: .6em;
  font-size: 1.6em;
  font-weight: 300;
  line-height: 1.4;
}

a {
  color: #fff;
  text-decoration: none;
  position: relative;
}

a:after {
  content: "";
  z-index: -1;
  background-color: #fdf3be;
  transition: top .2s cubic-bezier(0, .8, .13, 1);
  position: absolute;
  inset: 95% -.1em 0;
}

a:hover:after {
  top: 0%;
}

a:hover {
  color: #121314;
}

li {
  margin-top: .3em;
  font-size: 1.6em;
  font-weight: 300;
  line-height: 1.4;
}

.visitors {
  position: absolute;
  top: 2.5vh;
  right: 4vw;
}

.number {
  font-feature-settings: "tnum";
  font-size: 35px;
  font-weight: 600;
}

.number + .number {
  margin-left: 2vw;
}

.orange {
  color: #ff5627;
}

.green {
  color: #49c628;
}

.word {
  color: #fff143;
  letter-spacing: .3rem;
  -webkit-writing-mode: vertical-lr;
  writing-mode: vertical-lr;
  text-align-last: justify;
  text-align: justify;
  text-justify: distribute-all-lines;
  font-size: 3.2rem;
  transition: transform .4s ease-in;
  position: absolute;
  top: 15vh;
  right: 5vw;
}

#node-scroll-button {
  cursor: pointer;
  padding: 1vw;
  transition: opacity .3s ease-in;
  animation: 2s infinite bounce;
  position: absolute;
  bottom: 1vh;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}

#goTop {
  cursor: pointer;
  padding: 10px;
  display: none;
  position: fixed;
  bottom: 2vh;
  right: .5vw;
}

@media (width <= 639px) {
  .word {
    font-size: 1rem;
    right: 2vw;
  }
}

@media (width <= 700px) {
  .three-container {
    display: none;
  }
}

.three-container {
  visibility: hidden;
  cursor: pointer;
  position: absolute;
  top: 12vh;
  left: 68vw;
}

.love {
  text-emphasis: "♥" pink;
}

.brain {
  text-emphasis: "🧠" pink;
}

.footer {
  color: #f5f5f5;
  background-color: #999;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  display: flex;
}
/*# sourceMappingURL=index.6a6928dc.css.map */
